import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ReviewResponseTemplateService } from '../../services/review-response-template.service';

@Component({
  selector: 'app-review-template-card',
  templateUrl: './review-template-card.component.html',
  styleUrls: ['./review-template-card.component.scss'],
})
export class ReviewTemplateCard implements OnChanges {
  @Input() reviewTemplates: any[] = [];
  @Input() isDeletingTemplate: boolean = false;
  @Output() deleteTemplateEvent: EventEmitter<any> = new EventEmitter<any>();

  public spinnerAnimationDuration: string = '0.8s';

  constructor(private router: Router, private reviewResponseTemplateService: ReviewResponseTemplateService) {}

  /**
   * @description Redirect to add-template-response page to edit the template.
   * @param template template object to be modified
   */
  public editTemplate(template): void {
    this.reviewResponseTemplateService.setReviewTemplateUpdate(template);
    this.router.navigate(['/add-template-response', template?.id]);
  }

  /**
   * @description Delete the template.
   * @param template template object to be deleted
   */
  public deleteTemplate(template: any): void {
    this.deleteTemplateEvent.emit(template);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    this.reviewTemplates = changes?.reviewTemplates?.currentValue;
    this.isDeletingTemplate = changes?.isDeletingTemplate?.currentValue;
  }
}
