<div class="template">
  <form [formGroup]="templateForm" class="template_form">

    <div class="template_form__block">
      <!-- All filters -->
      <div class="template_filters">
        <div class="first_filter">
          <p class="first_filter__header">Account/Location filter</p>
          <!-- Accounts... -->
          <div class="first_filter__accounts">
            <p class="title">Accounts *   </p>
            <p-multiSelect class="" [options]="accounts" optionValue="_id" formControlName="accounts" optionLabel="ident"
              placeholder="Select Accounts" variant="filled" [virtualScroll]="true" displaySelectedLabel="true"
              maxSelectedLabels="1" selectedItemsLabel="{0} Accounts selected">
            </p-multiSelect>
          </div>
          <!-- Tags -->
          <div class="first_filter__tags">
            <p class="title">Tags</p>
            <p-multiSelect [options]="tags" formControlName="tags" optionLabel="tagName" placeholder="Select Tags"
              optionValue="tagName" variant="filled" [virtualScroll]="true" displaySelectedLabel="true"
              maxSelectedLabels="1" selectedItemsLabel="{0} Tags selected"></p-multiSelect>
          </div>
        </div>

        <div class="second_filter">
          <p class="second_filter__header">Review filter</p>
          <!-- Star Rating... -->
          <div class="second_filter__starating">
            <p class="title">Star Rating</p>
            <ng-container formGroupName="star_rating" *ngFor="let rating of starRatingsData">
              <p class="star_inputs"><input class="star_inputs__checkbox" type="checkbox" value="rating.value"
                  [formControlName]="rating.controlValue">
                {{ rating.label }}</p>
            </ng-container>
          </div>

          <!-- Thumb rating... -->
          <div class="second_filter__thumbrating">
            <p class="title">Thumb Rating</p>
            <p class="thumb_inputs">
              <p-radioButton class="thumb_inputs__checkbox" formControlName="thumb_rating" value="up"></p-radioButton>
              Up
            </p>
            <p class="thumb_inputs">
              <p-radioButton class="thumb_inputs__checkbox" formControlName="thumb_rating" value="down"></p-radioButton>
              Down
            </p>
            <p class="thumb_inputs">
              <p-radioButton class="thumb_inputs__checkbox" formControlName="thumb_rating" value="null"></p-radioButton>
              All
            </p>
          </div>

          <!-- Review text... -->
          <div class="second_filter__reviewtext">
            <p class="title">Review Text</p>
            <p class="review_inputs">
              <p-radioButton class="review_inputs__checkbox" formControlName="review_text_presence" value="true"></p-radioButton>
              Only reviews with text
            </p>
            <p class="review_inputs">
              <p-radioButton class="review_inputs__checkbox" formControlName="review_text_presence" value="false"></p-radioButton>
              Only reviews without text
            </p>
            <p class="review_inputs">
              <p-radioButton class="review_inputs__checkbox" formControlName="review_text_presence" value="null"></p-radioButton>
              All
            </p>
          </div>
        </div>
      </div>

      <!-- Response block -->
      <div class="template_response" formArrayName="responses">
        <div *ngFor="let response of responses.controls; let i=index;" class="template_response__block">
          <textarea class="response__block__inputs" rows="5" cols="80" pInputTextarea [formControlName]="i"
            [autoResize]="true" variant="filled" (focus)="selectedResponseBlock(i)">
              </textarea>
          <p-button class="response__block__delete" icon="pi pi-trash" rounded="true" severity="danger" outlined="true"
            (click)="deleteResponse(i)">
          </p-button>
        </div>
        <div class="template_response__cta">
          <p-button label="Add a response" severity="success" (click)="addResponse()"></p-button>
        </div>
      </div>

      <!-- Variables list -->
      <div class="variables">
        <p class="variables_headers">Variables Menu</p>
        <p-dropdown class="variables_dropdown" [options]="variablesList" optionLabel="name" optionValue="value"
          placeholder="Select variables" (onChange)="addVariable($event)"> </p-dropdown>
      </div>
    </div>

    <div class="save_template">
      <p-button [label]="!templateId ? 'Save' : 'Edit'" (click)="saveTemplate()" severity="success" [disabled]="!isFormValid"
        [pTooltip]="!isFormValid ? 'Please select at leat 1 account to create a template.' : ''">
      </p-button>
      <!-- Display spinner.. -->
      <p-progressSpinner *ngIf="isLoading" styleClass="w-2rem h-2rem" strokeWidth="2" fill="var(--surface-ground)"
        animationDuration="spinnerAnimationDuration">
      </p-progressSpinner>
    </div>

  </form>
</div>
