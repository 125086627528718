import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocChangeLogService } from './loc-change-log.service';
import { SessionService } from '../core/backend-adapter/session.service';
import { formatDate } from '@angular/common';
import { NotifyService } from '../core/layouts/notifications/notify/notify.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loc-change-log',
  styleUrls: ['./loc-change-log.component.scss'],
  templateUrl: './loc-change-log.component.html',
})
export class LocChangeLogComponent implements OnInit, OnDestroy {
  constructor(
    private locChangeLogService: LocChangeLogService,
    private sessionService: SessionService,
    private notifyService: NotifyService
  ) {}

  private userLoginId: number;
  private accountId: number;
  private query: string = '';
  private ngUnsubscribe$: Subject<any> = new Subject();
  private currentPageNumber: number;

  public changeLocationsDataList: any[] = [];
  public tableHeaderData: any[] = [
    {
      label: 'Account',
      enableSorting: false,
      sortingLable: 'account',
      fieldName: 'account',
      className: 'account',
    },
    {
      label: 'Storecode',
      enableSorting: true,
      sortingLable: 'Storecode',
      fieldName: 'Storecode',
      className: 'Storecode',
    },
    {
      label: 'Address line 1',
      enableSorting: false,
      sortingLable: 'addressLine',
      fieldName: 'addressLine',
      className: 'addressLine',
    },
    {
      label: 'City',
      enableSorting: false,
      sortingLable: 'city',
      fieldName: 'city',
      className: 'city',
    },
    {
      label: 'State',
      enableSorting: false,
      sortingLable: 'state',
      fieldName: 'state',
      className: 'state',
    },
    {
      label: 'Opening date',
      enableSorting: false,
      sortingLable: 'openingdate',
      fieldName: 'openingdate',
      className: 'openingdate',
    },
    {
      label: 'Updated at',
      enableSorting: true,
      sortingLable: 'updatedat',
      fieldName: 'updatedat',
      className: 'updatedat',
    },
    {
      label: 'Updated by',
      enableSorting: true,
      sortingLable: 'updatedby',
      fieldName: 'updatedby',
      className: 'updatedby',
    },
    {
      label: 'Field name',
      enableSorting: true,
      sortingLable: 'fieldname',
      fieldName: 'fieldname',
      className: 'fieldname',
    },
    {
      label: 'Old value',
      enableSorting: false,
      sortingLable: 'oldvalue',
      fieldName: 'oldvalue',
      className: 'oldvalue',
    },
    {
      label: 'New value',
      enableSorting: false,
      sortingLable: 'newvalue',
      fieldName: 'newvalue',
      className: 'newvalue',
    },
  ];
  public selectedRange: number = 3;
  public dateRanges = [
    { name: 'Last 30 days', code: 3 },
    { name: 'Last 90 days', code: 6 },
  ];
  public customDate: any;
  public displayDropdown: boolean = false;
  public tableColumnHeaders: any[] = [];
  public isLoadingData: boolean;
  public isCustomDateSelected: boolean = false;
  public placegolderText: string = 'Select date range';
  //Pagination.
  public first: number = 0;
  public rows: number = 20;
  public totalRecords: number = 0;

  ngOnInit(): void {
    this.tableColumnHeaders = this.locChangeLogService.tableColums;

    this.sessionService.getCurrentUser$().subscribe((user) => {
      this.userLoginId = user?.login?._id;
    });

    this.sessionService.getSelectedAccount$().subscribe((account) => {
      this.accountId = account?._id || null;
    });

    // Init query with 3 months data.
    this.query = `${this.selectedRange} months`;
    this.placegolderText = this.selectedRange ? 'Select date range' : `Select custom date range`;
    const paramName: string = `interval`;

    // this.getChangeLodData(paramName, 1);
  }

  public pageChange(event): void {
    this.first = event.first;
    this.rows = event.rows;
  }

  public onCalendarChange(event): void {
    this.isCustomDateSelected = true;
    this.selectedRange = null;
    this.placegolderText = !this.selectedRange ? 'Select date range' : `Select custom date range`;
    const year = formatDate(event, 'yyyy', 'en-US').replace(/-/g, '');
    const month = formatDate(event, 'MM', 'en-US').replace(/-/g, '');
    this.query = `${year}-${month}`;
    const paramName = `date`;
    this.currentPageNumber = 1; // Reset the page number to 1.
    this.getChangeLodData(paramName, this.currentPageNumber);
  }

  public onDropdownChange(event): void {
    this.isCustomDateSelected = false;
    this.customDate = null;
    this.placegolderText = !this.customDate ? 'Select date range' : `Select custom date range`;
    this.query = `${event?.value || this.selectedRange} months`;
    const paramName: string = `interval`;
    this.currentPageNumber = 1; // Reset the page number to 1.
    this.getChangeLodData(paramName, this.currentPageNumber);
  }

  private getChangeLodData(paramName: string, pageNumber: number): void {
    this.isLoadingData = true;
    if (!this.accountId) {
      this.notifyService.error('Please select an account to view the data', 10000);
      this.isLoadingData = false;
    } else {
      this.locChangeLogService.getLocChangeLogData(this.userLoginId, this.accountId, this.query, paramName, pageNumber).subscribe(
        (response) => {
          this.changeLocationsDataList = response['changes'].map((el) => {
            if (Array.isArray(el?.old_value) || Array.isArray(el.new_value)) {
              return {
                ...el,
                updated_at: formatDate(new Date(el.updated_at), 'yyyy-MM-dd', 'en-US'),
              };
            } else {
              return {
                ...el,
                old_value: [el.old_value],
                new_value: [el.new_value],
                updated_at: formatDate(new Date(el.updated_at), 'yyyy-MM-dd', 'en-US'),
              };
            }
          });
          this.totalRecords = response['total'];
          if (!this.changeLocationsDataList.length) {
            this.notifyService.info('No data available for selected range.');
          }
          this.isLoadingData = false;
          this.query = '';
        },
        (error) => {
          this.isLoadingData = false;
          this.query = '';
        },
        () => {
          this.isLoadingData = false;
          this.query = '';
        }
      );
    }
  }

  lazyLoadLocations(event) {
    this.currentPageNumber = (event.first / 25) + 1;
    if(this.selectedRange) {
      this.query = `${this.selectedRange} months`;
      this.getChangeLodData('interval', this.currentPageNumber);
    } else {
      const year = formatDate(this.customDate, 'yyyy', 'en-US').replace(/-/g, '');
      const month = formatDate(this.customDate, 'MM', 'en-US').replace(/-/g, '');
      this.query = `${year}-${month}`;
      this.getChangeLodData('date', this.currentPageNumber);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
