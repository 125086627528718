<div *ngIf="isDeletingTemplate" class="delete">
  <p class="delete_msg">Deleting template...</p>
  <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="2" fill="var(--surface-ground)"
    animationDuration="spinnerAnimationDuration">
  </p-progressSpinner>
</div>

<div *ngFor="let template of reviewTemplates">
  <div class="card">
    <div class="card_loc__filters">
      <!-- Locations filter block -->
      <div class="cta">
        <p class="card_loc__title">Location filters</p>
        <div>
          <i class="pi pi-pencil cta_btns__edit" (click)="editTemplate(template)"></i>
          <i class="pi pi-trash cta_btns__delete" (click)="deleteTemplate(template)"></i>
        </div>
      </div>
      <!-- List of accounts -->
      <div class="accounts">
        <span class="accounts_title">Account: </span>
        <ng-container *ngFor="let ident of template?.ident">
          <span class="accounts_list">{{ ident }},</span>
        </ng-container>
      </div>
      <!-- List of tags -->
      <div class="tags">
        <span class="tags_title">Tags: </span>
        <ng-container *ngFor="let tag of template?.tags">
          <span class="tags_list">{{ tag }},</span>
        </ng-container>
        <ng-container *ngIf="!template?.tags?.length">
          <span class="tags_list">N/A</span>
        </ng-container>
      </div>
    </div>
    <div class="card_reviews__filter">
      <p class="card_reviews__title">Review filters</p>
      <!-- Star rating -->
      <div class="starating">
        <span class="starating_title">Star rating: </span>
        <ng-container *ngIf="template.filter.star_rating.length">
          <ng-container *ngFor="let rating of template.filter.star_rating">
            <span class="starating_number">{{ rating }},</span>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!template.filter.star_rating.length">
          <span class="starating_number">N/A</span>
        </ng-container>
      </div>
      <!-- Thumb rating -->
      <div class="thumbrating">
        <span class="thumbrating_title">Thumb rating: </span>
        <span class="thumbrating_text">{{ ((template.filter.thumb_rating === 'null') ? 'Any' :
          template.filter.thumb_rating) }}</span>
        <!-- </ng-container> -->
      </div>
      <!-- Review text -->
      <div class="reviewtext">
        <span class="reviewtext_title">Review text: </span>
        <span class="reviewtext_text">{{ ((template.filter.review_text_presence === 'null') ? 'Any' :
          template.filter.review_text_presence) }}</span>
      </div>
    </div>
  </div>
</div>
